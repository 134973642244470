export const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

export const cleanHTMLAndPreserveCustomElement = (inputHTML: string) => {
    const placeholders = [];

    // Replace custom elements with placeholders
    let placeholderHTML = replaceCustomElementsWithPlaceholders(inputHTML, placeholders);

    // Save placeholders for later use
    //  placeholders = inputHTML.match(/PLACEHOLDER\d+/g) || [];

    placeholderHTML = cleanHTML(placeholderHTML);

    // Replace placeholders with original custom element content
    var result = replacePlaceholdersWithCustomElements(placeholderHTML, placeholders);

    return result;
}

export const cleanHTML = (input: string) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.innerHTML;
}

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const lowerNoDiacritic = (str) => {
    return str.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

export const formatDateTime = (datePart, timePart) => {
    if (datePart == null) {
        return null;
    }
    // var monthNames = [{$trans['jana']}, {$trans['feba']}, {$trans['mara']}, {$trans['apra']}, {$trans['maya']}, {$trans['juna']}, {$trans['jula']}, {$trans['auga']}, {$trans['sepa']}, {$trans['octa']}, {$trans['nova']}, {$trans['deca']}];
    var monthNames = ['januára', 'februára', 'marca', 'apríla', 'mája', 'júna', 'júla', 'augusta', 'septembra', 'októbra', 'novembra', 'decembra'];
    // // Parse input date string
    // const date = new Date(inputDate);
    //
    // // Adjust for UTC timezone
    // const utcYear = date.getUTCFullYear();
    // const utcMonth = date.getUTCMonth();
    // const utcDay = date.getUTCDate();
    // const utcHours = date.getUTCHours();
    // const utcMinutes = date.getUTCMinutes();
    //
    // // Get components
    // const month = monthNames[utcMonth];
    //
    // // Format the date
    // return `${utcDay}. ${month} ${utcYear} o ${utcHours}:${utcMinutes < 10 ? '0' : ''}${utcMinutes}`;

    // const datePart = inputDate.split('T')[0];
    // const timePart = inputDate.split('T')[1];
    var date = new Date(datePart);
    var time = new Date(timePart);
    return date.getDate() + '. ' + monthNames[date.getMonth()] + ' ' + date.getFullYear()
        + ' o '
        + time.getHours()
        + ':'
        + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes());
}

function replaceCustomElementsWithPlaceholders(input, placeholders) {
    // Use regular expression to find all occurrences of <customelement>...</customelement>
    var matches = input.match(/<customelement\b[^>]*>[\s\S]*?<\/customelement>/ig);

    // Replace each occurrence with a placeholder
    if (matches) {
        for (var i = 0; i < matches.length; i++) {
            placeholders.push(matches[i]);
            var placeholder = 'PLACEHOLDER' + (i + 1);
            input = input.replace(matches[i], placeholder);
        }
    }

    return input;
}

function replacePlaceholdersWithCustomElements(input, placeholders) {
    // Replace each placeholder with the corresponding customelement content
    for (var i = 0; i < placeholders.length; i++) {
        var placeholder = 'PLACEHOLDER' + (i + 1);
        input = input.replace(placeholder, placeholders[i]);
    }

    return input;
}

export const getAllEntitiesLink = (baseLink: string, categoryName: string, filter: string, logic: string) => {
    let link = baseLink;
    if (categoryName) {
        link = link + '/' + categoryName;
    }

    if (filter) {
        const linkParams: Record<string, string> = {
            'f_categories': filter,
        };

        if (filter.split(',').length > 1) {
            linkParams['f_categories_op'] = logic ?? 'or'
        }

        link += `?${buildQuery(linkParams)}`;
    }

    return link;
};

